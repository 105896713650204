export default [
  {
    title: 'Campaigns',
    icon: 'AwardIcon',
    children: [
      {
        title: 'All Campaigns',
        route: 'browse-campaigns',
        // acl
        action: 'read',
        resource: 'Campaign',
      },
      {
        title: 'Create Campaign',
        route: 'create-campaign',
        // acl
        action: 'create',
        resource: 'Campaign',
      },
    ],
  },
]
