export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    // acl
    action: 'read',
    resource: 'Dashboard',
  },
  /* {
    title: 'Users',
    icon: 'UsersIcon',
    route: null,
  }, */
  /* {
    title: 'Roles',
    icon: 'LockIcon',
    route: null,
  }, */
]
