export default [
  {
    title: 'Company',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Manage Company',
        route: 'view-mycompany',
        // acl
        action: 'read',
        resource: 'Company',
      },
      {
        title: 'Create Company',
        route: 'create-company',
        // acl
        action: 'create',
        resource: 'role-admin',
      },
    ],
  },
]
